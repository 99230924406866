.flightData {
  font-size: 16px;
  font-weight: bold;
}

.scrollableView {
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.btnYellow {
  background-color: #fff !important;
  border-radius: 10px;
  border-color: #3b5e75 !important;
  border-width: 3px !important;
  color: #000000 !important;
}

.btnGray {
  background-color: #fff !important;
  border-radius: 10px;
  border-color: lightgrey !important;
  border-width: 3px !important;
  color: #000000 !important;
}

h6 {
  font-size: 9px !important;
}

.flightPosted {
  background-color: #f2f6f9 !important;
  border: 0.5px solid #3b5e75 !important;
}

.flightNotPosted {
  border: 3px solid lightgrey !important;
  border-radius: 10px !important;
}

.listHeader {
  background-color: #fff;
  border-bottom: 3px solid rgb(255, 230, 0);
}

.btnYellowBordered {
  border-color: rgb(255, 230, 0) !important ;
  color: #000000 !important;
  background-color: #fff !important;
}
.textCity {
  font-size: 10px !important;
  white-space: nowrap !important;
}

.btn-yellow {
  border: 2px solid yellow !important;
  background-color: #fff !important;
}

.btn-orange {
  border: 2px solid rgb(250, 196, 95) !important;
  background-color: #fff !important;
}

.btn-red {
  border: 2px solid rgb(246, 102, 102) !important;
  background-color: #fff !important;
}

/* .badge-yellow {
  border: 2px solid yellow !important;
  background-color: #fff !important;
}

.badge-orange {
  border: 2px solid rgb(250, 196, 95) !important;
  background-color: #fff !important;
}

.badge-red {
  border: 2px solid rgb(246, 102, 102) !important;
  background-color: #fff !important;
} */

button {
  margin: 2px !important;
}
/* .listHeaderText{
    text-decoration: underline;
    text-decoration-color: rgb(255, 230, 0) !important;
} */
.btnDarkBlueFlightLIst {
  background-color: #3b5e75 !important;
  border-radius: 15px !important;
  color: rgb(255, 230, 0) !important;
}
