div.pathArea {
  background-color: white;
  height: 100%;
  margin-bottom: 1rem;
  padding: 1rem;
  border-radius: 10px;
}

.PathTypeArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 2.5rem;
  margin-bottom: 0.5rem;
}
.PathTypeAreaOld {
  display: flex;
  flex-direction: row;
  border: 1px solid lightgray;
  align-items: center;
  height: 2.5rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
}
.PathTypeButton {
  display: flex;
  width: 50%;
  height: 100%;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  color: #000000;
  border: 3px solid #fff;
}
.PathTypeButton-no-border {
  display: flex;
  width: 50%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  color: #3b5e75;
}

.PathTypeButton.selected {
  border-bottom: 5px solid rgb(255, 230, 0) !important;
}
.PathTypeButton-no-border.selected-buy {
  border-right: 1px solid #3b5e75;
  border-bottom: 0.57px solid #3b5e75;
  border-bottom-right-radius: 15px;
}
.PathTypeButton-no-border.selected-sell {
  border-left: 1px solid #3b5e75;
  border-bottom: 0.57px solid #3b5e75;
  border-bottom-left-radius: 15px;
}

.bg-primary {
  background-color: rgb(255, 230, 0);
}
.none-boder {
  border: none;
}
