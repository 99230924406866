@font-face {
  font-family: "HkGrotesk";
  src: url("./assets/fonts/hk-grotesk/HKGrotesk-Regular.otf");
}
body {
  font-family: "HkGrotesk" !important;
}

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #000000;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #000000;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

::-moz-selection {
  /* Code for Firefox */
  color: rgb(255, 230, 0) !important;
  background: #000000;
}

::selection {
  color: rgb(255, 230, 0) !important;
  background: #000000;
}

/* body{
  font-family: Apercu !important;
} */

/* PRESS SECTION */
.newsdiv {
  display: contents;
  margin-top: 50px;
  justify-content: center;
  align-items: center;
  height: 100vh;
}
.useactive {
  background-color: yellow !important;
}
.news-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.news-card,
.news-card-press {
  width: calc(33.33% - 40px);
  display: inline-block;
  height: 350px;
  margin: 20px;
  padding: 20px;
  text-align: center;
  border: 0.5px solid grey;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
}
.news-card-press {
  border-radius: 20px;
  background: #f2f6f9;
  box-shadow: 13px 13px 20px 3px rgba(0, 0, 0, 0.08);
  border: none;
  text-align: start;
  height: 300px;
}
@media only screen and (max-width: 768px) {
  .news-card,
  .news-card-press {
    width: 100%;
    display: block;
    margin: 20px 20px 20px;
  }
}

.news-card:hover {
  box-shadow: 0 0 10px grey;
  transform: scale(1.05);
}

.news-card-press:hover {
  background-color: #d6ebff;
}
.news-card img {
  width: 100%;
  height: 200px;
  object-fit: contain;
}
.news-card-press img {
  width: auto;
  height: 45px;
  object-fit: contain;
}
.news-card-press p {
  height: 45px;
  line-height: 47px;
}
.news-card-press h2 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.news-card h2 {
  margin-top: 15px;
  font-weight: bold;
}
.news-card-press > a {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.news-card .date {
  align-items: right;
  font-size: 15px;
  font-weight: bold;
  color: #777;
  margin-top: 10px;
}
.news-card-press .news-card-icon {
  background: #d6ebff;
  height: 25px;
  width: 25px;
  border-radius: 50%;
  color: black;
  transition: all 0.25s ease;
  margin-top: auto;
}
.news-card-press:hover .news-card-icon {
  background: #50aaf2;
  margin-left: 10px;
}
.Browse-section {
  display: flex;
  justify-content: space-between;
  gap: 2rem;
  background: #f2f6f9;
  border-radius: 20px;
  padding: 1rem;
}
.home-card-container {
  width: 100%;
  /* display: flex; */
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 10px 20px 10px 30px;
  /* Gap on right and left */
}

/* HOME CARD */
.Browse-card-section::-webkit-scrollbar {
  display: none;
}

.homecard {
  flex: 0 0 calc(100% / 3); /* Ensures exactly 3 cards fit */
  max-width: calc(100% / 3);
  min-height: 300px;
  background-color: #3b5e75;
  border-radius: 15px;
  overflow: hidden;
}

.homecard img {
  width: 100%;
  display: block;
}

.home-card-image-wrapper {
  position: relative;
  height: max-content;

  /* This is just an example. Set it to the height of your images. */
  overflow: hidden;
  /* This ensures nothing spills out of the wrapper. */
}
.home-card-image-wrapper img {
  display: block;
}

.home-card-content {
  padding: 1rem;
}

.expanded .home-card-description {
  display: block;
  padding: 10px;
  /* Add padding only when the card is expanded */
}
.home-card-arrow-icon {
  cursor: pointer;
  display: none;
  position: absolute;
  left: 50%;
  bottom: 10px;
  transform: translateX(-50%);
  background-color: yellow;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  /* ensures it's above other content */
}
.home-card-arrow-icon.expanded {
  transform: translateX(-50%) rotate(180deg);
}

.home-card-title {
  margin-top: 1rem;
  color: #ffe000;
  font-weight: 700;
  text-align: center;
}
.Footer-img-logo {
  display: flex;
  justify-content: space-around;
  width: 100%;
}
.marquee-container {
  overflow: hidden;
  white-space: nowrap;
  width: 100%;
  position: relative;
}

.marquee {
  display: flex;
  gap: 40px;
  animation: marquee-scroll 20s linear infinite;
  width: fit-content;
}

.marquee a {
  flex-shrink: 0;
}

.marquee img {
  height: 80px;
  object-fit: contain;
  aspect-ratio: 2/1;
  display: block;
}

/* Smooth continuous scroll effect */
@keyframes marquee-scroll {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-50%);
  }
}

/* Pause on hover */
.marquee-container:hover .marquee {
  animation-play-state: paused;
}

.Footer-img-logo img {
  width: 100%;
  height: 80px;
  object-fit: contain;
  aspect-ratio: 2/1;
}
.Find-out-how {
  display: flex;
  flex-direction: column;
  width: 35%;
}
.Find-out-how > h1 {
  color: #3b5e75;
  font-size: 3rem;
}
.Find-out-how > p {
  padding: 0 8px;
  font-size: 13px;
  width: fit-content;
  color: #ffe000;
  background-color: #3b5e75;
  border-radius: 5px;
}
.empty-leg-page-content {
  padding: 1rem 4rem;
}
.Browse-card-section {
  width: 65%;
  display: flex;
  overflow-x: auto;
  scroll-behavior: smooth;
  gap: 10px;
}
/* ... Other styles ... */
.The-News {
  font-weight: bolder;
  font-size: 30px;
}
/* Carousel Card Specific Styles */
.carousel-card {
  width: calc(33.33% - 20px);
  margin: 0 10px;
}

/* Responsive Carousel Styles */
@media (max-width: 768px) {
  .marquee img {
    height: 50px;
  }
  .marquee {
    gap: 15px;
  }
  .homecard {
    max-width: 100%;
    min-height: 200px;
  }
  .desktop-carousel {
    display: none !important;
  }

  .mobile-grid {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .carousel-card {
    width: 100%;
    margin: 10px 0;
  }
}

@media (min-width: 769px) {
  .mobile-grid {
    display: none !important;
  }

  .desktop-carousel {
    width: 100%;
  }
}
/* Media query for mobile view */
@media (max-width: 768px) {
  .empty-leg-page-content {
    padding: 1rem;
  }
  .The-News {
    font-size: 15px;
  }
  .Footer-img-logo {
    height: 4% !important;
    margin-bottom: 25px;
  }
  .home-card-arrow-icon {
    display: block;
  }
  .Browse-section {
    flex-direction: column;
  }
  .Find-out-how > h1 {
    font-size: 1.5rem;
  }
  .Find-out-how {
    width: 100%;
  }
  .Browse-card-section {
    width: 100%;
    flex-direction: column;
    gap: 0;
  }
  .home-card-description {
    /* display: none; */
    padding: 0 1px 4px 4px;
    /* font-size: 1.5rem; */
    /* Remove padding when not expanded */
  }

  .expanded .home-card-description {
    display: block;
    /* padding: 0 10px; */
    /* Add padding only when the card is expanded */
  }
}
.As-featured-in {
  color: #3b5e75;
  font-size: 20px;
  text-align: center;
  margin-top: 2rem;
}
