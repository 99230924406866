.footer-container {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #3b5e75;
  padding: 3rem 3rem 0.5rem 3rem;
  margin: 2rem;
  border-radius: 20px;
  color: white;
}
.footer-divItem h4 {
  font-weight: 100;
  color: white;
}
.route-and-logo {
  display: flex;
  justify-content: space-between;
}
.Shared-Flights-pc,
.Shared-Flights-mobile {
  color: white;
}
.Shared-Flights-mobile {
  display: none;
}
.Shared-Flights-pc {
  display: block;
}
@media (max-width: 768px) {
  .route-and-logo {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
  }
  .Shared-Flights-mobile {
    display: block;
  }
  .Shared-Flights-pc {
    display: none;
  }
  .footer-container {
    padding: 1rem 1rem 0.5rem 1rem;
    margin: 0 1rem 1rem 1rem;
    flex-wrap: nowrap;
  }
  .footer-copyright {
    margin-top: 2rem;
  }
}
@media screen and (max-width: 768px) {
  .desktop-only {
    display: none;
  }
}
