/* .alert {
    color: #525f7f !important;
    background-color: rgb(255, 230, 0) !important;
    border-color: rgb(255, 230, 0) !important;
} */

.btnYellow {
     border-radius: 10px !important;
     background-color: rgb(255, 230, 0) !important;
}

.btnGray{
     background-color:#fff !important;
     border-radius: 10px !important;
     border-color: lightgrey !important;
     border-width: 3px !important;
     color:#000000 !important;
}