.text--17 {
  font-size: 17px;
}
p {
  font-size: 14px;
  font-weight: 400;
}

.imgBackground {
  position: relative;
  display: flex;
  background-image: url("../assets/images/backgroundNew.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.imgBackgroundFlight {
  position: relative;
  display: flex;
  background-image: url("../assets/images/flight-background.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

.fullWidth {
  width: 100%;
  opacity: 0.8;
}

.btnYellow {
  background-color: #fff !important;
  border-radius: 10px;
  border-color: rgb(255, 230, 0) !important;
  border-width: 3px !important;
  color: #000000 !important;
}

.btnGray {
  background-color: #fff !important;
  border-radius: 10px;
  border-color: lightgrey !important;
  border-width: 3px !important;
  color: #000000 !important;
}

.MuiPickersToolbar-toolbar {
  background-color: rgb(255, 230, 0) !important;
}
.MuiPickersToolbarText-toolbarBtnSelected {
  color: #000000 !important;
}
.MuiPickersToolbarText-toolbarTxt {
  color: black !important;
}
.MuiPickersDay-daySelected {
  background-color: rgb(255, 230, 0) !important;
}

.MuiButton-textPrimary {
  border: 0px solid #3b5e75 !important;
  background-color: #3b5e75 !important;
  border-radius: 10px !important;
  color: #ffe000 !important;
  text-transform: capitalize !important;
}

.MuiPickersClock-pin {
  background-color: rgb(255, 230, 0) !important;
}

.MuiPickersClockPointer-pointer {
  background-color: rgb(255, 230, 0) !important;
}

.MuiPickersClockPointer-thumb {
  border: 14px solid rgb(255, 230, 0) !important;
}

.textInitial {
  padding-top: 5px;
  text-transform: uppercase;
  color: rgb(255, 230, 0) !important;
}
.How-to-container,
.legal-container {
  padding: 0 4rem;
}
.press-the-news-section {
  background-color: #f2f6f9;
  display: flex;
  justify-content: space-between;
  padding: 1rem 4rem;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 20px -5px;
}
.press-the-news-section p:nth-of-type(1) {
  font-weight: 700;
}
.press-the-news-section p:nth-of-type(2) {
  font-style: italic;
  font-size: 2.5rem;
  margin-bottom: 0;
}
.press-the-news-section > img {
  height: 100px;
  width: auto;
  object-fit: contain;
}
.pres-page-newsdiv {
  padding: 0 4rem;
}
.BarkAirRouteDescription-olsection {
  margin-left: 3rem;
  margin-top: 2rem;
}
@media only screen and (max-width: 768px) {
  .How-to-container,
  .legal-container {
    padding: 0 0;
  }
  .press-the-news-section > img {
    display: none;
  }
  .pres-page-newsdiv {
    padding: 0;
  }
  .press-the-news-section p:nth-of-type(2) {
    font-size: 1.5rem;
    margin-bottom: 0px;
  }
  .press-the-news-section {
    padding: 1rem;
  }
  .BarkAirRouteDescription-olsection {
    margin-left: 1rem;
  }
}
