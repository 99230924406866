body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  /* font-family: 'Apercu sans-serif' !important; */
}

html,
body {
  overflow-x: hidden;
}
.table-responsive::-webkit-scrollbar {
  height: 6px;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

.MuiFormLabel-root {
  font-family: "HkGrotesk" !important;
}
.MuiInputBase-input {
  font-family: "HkGrotesk" !important;
}
.to_from_text {
  color: #3b5e75 !important;
  font-weight: bold;
}
label.MuiFormLabel-root {
  color: #000000 !important;
}
button {
  box-shadow: none !important;
}

.dropdown-item:active {
  background-color: rgb(255, 230, 0) !important;
  color: #000000 !important;
}

.alertYellow {
  background-color: rgb(255, 230, 0) !important;
  color: #000000 !important;
  border-color: #000000 !important;
}
.alertBlue {
  background-color: #3b5e75 !important;
  color: rgb(255, 230, 0) !important;
}
.alertYellowInverse {
  background-color: #000000 !important;
  color: rgb(255, 230, 0) !important;
  border-color: #000000 !important;
}

.alertWhite {
  background-color: rgb(255, 255, 255) !important;
  color: #000000 !important;
  border-color: #000000 !important;
  font-size: 1.5rem !important;
  font-weight: bolder;
}

.text-dark {
  color: #000000 !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  color: #000000;
}
.sweet-alert {
  border: 3px solid rgb(255, 230, 0) !important;
  background-color: #fff !important;
  color: #000000 !important;
}

.btn-btnYellow {
  background-color: rgb(255, 230, 0) !important;
  color: #000000 !important;
}

.react-tel-input .form-control {
  font-size: 15px !important;
  width: 100% !important;
  border: none !important;
  border-bottom: 1px solid #949494 !important;
  border-radius: 0 !important;
  color: #212121 !important;
}

.social-logos {
  display: flex; /* Aligns items in a row */
  justify-content: start; /* Centers items horizontally */
  align-items: center; /* Centers items vertically */
  gap: 20px; /* Adjust this value to increase/decrease space between logos */
  margin-bottom: 10px;
  margin-top: 10px;
}

.social-logos a img {
  height: 24px; /* Adjust height to fit your design */
  width: auto; /* Keeps the aspect ratio of the logos */
  margin: 5px; /* Adds a little space around each logo */
}
.btndarkblue {
  background: #3b5e75 !important;
  color: #ffe000 !important;
  width: 100%;
  border-radius: 15px !important;
}
.btnBorderdarkblue {
  border: 3px solid #3b5e75 !important;
  color: black !important;
  width: 100%;
  border-radius: 15px !important;
}
.Inquire-btndarkblue {
  background: #3b5e75 !important;
  color: #ffe000 !important;
  width: 100%;
  border-radius: 15px !important;
  padding: 2px 0rem !important;
  font-weight: 100 !important;
  font-size: 14px !important;
}
