.badge-yellow {
  border: 2px solid yellow !important;
  background-color: #fff !important;
}

.badge-orange {
  border: 2px solid rgb(250, 196, 95) !important;
  background-color: #fff !important;
}

.badge-red {
  border: 2px solid rgb(246, 102, 102) !important;
  background-color: #fff !important;
}
