[data-initials]:before {
background: #fff;
color: #000;
opacity: 1; 
border:5px solid rgb(255, 230, 0);
content: attr(data-initials); 
display: inline-block; 
font-weight: bold; 
border-radius: 50%; 
font-size: 70px;
vertical-align: middle; 
margin-right: 0.5em; 
width: 150px; 
height: 150px; 
line-height: 150px; 
text-align: center; 
}