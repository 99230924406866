.testimonial-cointainer {
  display: flex;
  gap: 2rem;
  background: #f2f6f9;
  border-radius: 20px;
  margin: 0.5rem 3.5rem;
  padding: 1rem 1rem 0 1rem;
  position: relative;
  min-height: 500px;
}

.testimonial-cointainer > div:first-child {
  flex: 1;
}
.testimonial-cointainer > div:last-child {
  flex: 1;
  background-image: url("../assets/testimonial-new.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 0 20px 20px 0;
}
.testimonial-Customer-Stories {
  display: flex;
  flex-direction: column;
}
.testimonial-Customer-Stories > h1 {
  color: #3b5e75;
  font-size: 3rem;
}
.testimonial-Customer-Stories > p {
  padding: 0 8px;
  font-size: 13px;
  width: fit-content;
  color: #ffe000;
  background-color: #3b5e75;
  border-radius: 5px;
}
.testimonials-card {
  position: absolute !important;
  top: 50%; /* Position it vertically in the center */
  left: 50%; /* Start positioning from the middle */
  transform: translate(
    -50%,
    -50%
  ); /* Move back half the width and height to truly center */
  background-color: #ffffffd9;
  padding: 1rem;
  border-radius: 15px;
  border: 0.57px solid #3b5e75;
  color: #3b5e75;
  width: 30%;
  min-height: 80%;
}
@media screen and (max-width: 500px) {
  .testimonial-cointainer {
    min-height: 650px !important;
  }
  .testimonials-card {
    min-height: 70% !important;
  }
}
@media screen and (max-width: 1200px) {
  .testimonial-Customer-Stories > h1 {
    color: white;
    font-size: 18px;
  }
  .testimonial-cointainer {
    background-image: url("../assets/testimonial-new.jpg");
    background-size: cover;
  }
  .testimonial-cointainer > div:last-child {
    display: none;
  }
  .testimonials-card {
    width: 90%;
    min-height: 60%;
  }
}
@media (max-width: 768px) {
  .testimonial-cointainer {
    margin: 8px;
  }
}
.testimonials-card > .carousel-inner > .active {
  background-color: transparent;
}
.carousel-control-next-pc {
  opacity: 1 !important;
  right: -75% !important;
}
.carousel-control-next-mobile {
  opacity: 1 !important;
  right: 33% !important;
  bottom: -130% !important;
}
.carousel-control-prev-pc {
  opacity: 1 !important;
  left: -75% !important;
}
.carousel-control-prev-mobile {
  opacity: 1 !important;
  left: 33% !important;
  bottom: -130% !important;
}
