.Container {
  display: block;
  flex-direction: column;
  margin-left: 0.5rem;
  padding-left: 1rem;
  border-left: 5px solid rgb(255, 230, 0);
}

.CityType {
  font-weight: 600;
}

.MainArea {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 24px;
  font-weight: 700;
}

.Code {
  margin-left: 0.5rem;
}

.Dropdown {
  margin: 0px auto;
  position: relative;
  width: 100%;
  border-radius: 5px;
  z-index: 10;
}

.Dropdown ul {
  list-style: none;
  position: absolute;
  width: 100%;
  padding: 0;
  border: 1px solid lightgray;
  border-top: none;
  max-height: 200px;
  overflow-y: scroll;
  background: white;
}

.Dropdown ul li {
  padding: 0px 5px;
  overflow: hidden;
  cursor: pointer;
  font-weight: 400;
  transition: height 0.1s linear 0s;
  border-bottom: 1px solid #f2f2f2;
}

input.form-control {
  font-size: 18px !important;
}

.inputSignUp {
  display: inline;
}

.timesSignUp {
  display: inline;
  margin-top: -18px;
  margin-left: 95%;
  padding: 0;
  padding-bottom: 5px !important;
  cursor: pointer;
  z-index: 100;
  font-size: 20px;
}

.timesPosition {
  display: inline;
  margin-top: -60px;
  margin-left: 97%;
  padding: 0;
  padding-bottom: 5px !important;
  cursor: pointer !important;
  /* z-index: 100; */
  font-size: 20px;
  /* position: absolute;
  top: 455px;
  right: 30px;
  cursor: pointer; */
}
.Dropdown ul li:hover {
  background: #f2f2f2;
}
.Dropdown > ul > li > p {
  color: #9b9b9b;
  margin-bottom: 0;
}
.Dropdown > ul > li > p:first-of-type {
  display: flex;
  justify-content: space-between;
}
.Dropdown > ul > li > p:nth-of-type(2) {
  font-size: 12px;
  text-align: start;
}
.Dropdown > ul > li > p:first-of-type > span:first-of-type {
  color: black;
  font-size: 14px;
}
.Dropdown > ul > li > p:first-of-type > span:nth-of-type(2) {
  color: #747474;
  font-size: 14px;
}
