.imgBackground {
  position: relative;
  display: flex;
  background-image: url("../assets/images/backgroundNew.jpg");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}

:root {
  --sticky-top: 125px;
}

/* input[type='radio']:before {
    content:'';
    display:block;
    width:60%;
    height:60%;
    margin: 20% auto;    
    border-radius:50%;    
}

input[type='radio']:checked:before {
    background:#000000 !important;
} */

input[type="radio"] {
  border: 2px solid white !important;
  box-shadow: 0 0 0 1px black !important;
  appearance: none !important;
  border-radius: 50% !important;
  width: 12px;
  height: 12px;
  background-color: #fff;
  transition: all ease-in 0.2s;
}

input[type="radio"]:checked {
  background-color: black;
}

.fullWidth {
  width: 100%;
  opacity: 0.8;
}

.fullWidthHomeSearch {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  /* opacity: 0.8; */
}
.fullWidthHomeSearchOld {
  width: 100%;
}
.fullWidthHomeSearchSell {
  width: 100%;
  padding-left: 10px;
}
.btnYellow {
  background-color: #fff !important;
  border-radius: 10px;
  border-width: 2px !important;
  border-color: rgb(255, 230, 0) !important;
  color: #000000 !important;
}

.btnGray {
  background-color: #fff !important;
  border-radius: 10px;
  border-color: lightgrey !important;
  border-width: 3px !important;
  color: #000000 !important;
}

.btnSwap {
  height: 50px;
  width: 50px;
  position: absolute;
  /* right: 10px;
     top: -43px; */
  cursor: pointer;
  background: transparent;
  border: 10px solid transparent;
  border-radius: 10px;
  transform: rotate(90deg);
}
.btnSwapNotRotate {
  height: 50px;
  width: 50px;
  position: absolute;
  /* right: 10px;
     top: -43px; */
  cursor: pointer;
  background: transparent;
  border: 10px solid transparent;
  border-radius: 10px;
}
.date-filter-div > div {
  margin: 1rem 0;
}
.date-filter-div {
  display: flex;
  justify-content: space-between;
}
.Filter-by-div {
}
.Filter-by-div div:nth-child(1) {
  margin-left: 5px;
  font-size: 16px;
  font-weight: bold;
}
.Filter-by-div div:nth-child(2) {
  display: none;
}
/* Add a media query for mobile devices */
@media screen and (max-width: 768px) {
  /* Adjust the max-width as needed for your target mobile devices */
  .date-filter-div div:nth-child(2) {
    display: none;
  }
  .Filter-by-div div:nth-child(2) {
    display: block;
  }
  .Filter-by-div {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .btnSwap {
    transform: rotate(0deg);
  }
  .HomePage-Bannersection {
    flex-direction: column;
  }
  .HomePage-Bannersection > h1 {
    width: 100% !important;
    text-align: center;
  }
  .date-filter-div > div {
    margin: 1rem 0 1rem 1.2rem;
  }
}
.home-page-seacrch-section {
  margin-left: 3rem !important;
}
.sticky-component {
  /* background-color: yellow; */
  /* border: 1px solid #ddd; */
  /* border-radius: 5px; */
  padding: 10px;
  margin-top: 0px;
  padding-top: 0px;
  width: 100%;
  padding-bottom: 0px;
  padding-left: 0px;
  border-bottom: 1px solid;
}

.is-sticky {
  position: sticky;
  padding: 0;
  top: var(--sticky-top);
  /* top: 120px; */
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}
.imgBackground > .BannerBottom-image {
  position: absolute;
  bottom: -20px;
  right: 10px;
  z-index: 9;
}
.HomePage-Bannersection {
  display: flex;
  width: max-content;
}
.HomePage-Bannersection > h1 {
  width: 30%;
  margin-right: 4rem;
}
.date-filter-button > button {
  margin: 0 !important;
  text-transform: none;
}
