* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #f5f5f5;
  font-family: sans-serif;
}

.pricing-container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 20px;
}

.plan {
  display: flex;
  flex-direction: column;
  position: relative;
  /* justify-content: space-between; */
  align-items: center;
  width: 300px;
  height: fit-content;
  margin: 20px;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: all 0.3s ease-in-out;
}

.plan:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.2);
}

.plan-header {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: center;
  justify-content: center;
  height: 323px;
  width: 100%;
  background-color: #3b5e75;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.plan-image {
  display: flex;
  justify-content: center;
  /* align-items: center; */
  height: 200px;
  width: 100%;
  overflow: hidden;
}

.plan-image img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.plan-title {
  color: white;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.plan-price {
  color: white;
  font-size: 36px;
  font-weight: bold;
  margin-bottom: 20px;
}

.plan-price span {
  font-size: 16px;
  font-weight: normal;
}

.plan-features {
  list-style: none;
  padding: 20px;
  /* text-align: center; */
}

.plan-features li {
  font-size: 18px;
  margin-bottom: 10px;
}

/* .plan-button {
  background-color: #FFE600;
  color: black;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  border: none;
  border-radius: 0px 0px 20px 20px;
  padding: 20px;
  width: 100%;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.plan-button:hover {
  background-color: #FFE600;
} */

.plan-button {
  display: block;
  background-color: #3b5e75;
  color: white;
  font-size: 18px;
  font-weight: 800;
  text-align: center;
  padding: 10px;
  margin: 20px auto;
  border-radius: 30px;
  width: 80%;
  border: 0px;
  transition: transform 0.3s ease-in-out;
}

.coupon-button {
  display: block;
  background-color: #3b5e75;
  color: #ffffff;
  font-weight: 800;
  text-align: center;
  padding: 10px;
  margin: 20px auto;
  border: 0px;
  transition: transform 0.3s ease-in-out;
  width: auto;
  font-size: 14px;
  border-radius: 12px;
}

.plan-button:hover {
  color: #3b5e75;
  transform: scale(1.05);
  background-color: white;
  border: 2px solid #3b5e75;
  /* box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.1); */
}
.plan-features {
  list-style-type: none;
}

.plan-features li::before {
  content: "\2713";
  margin-right: 10px;
  color: #3b5e75;
}

.coupon-code {
  display: flex;
  justify-content: center;
}

.coupon-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;
  padding: 10px;
}

.coupon-label {
  font-size: 18px;
  margin-bottom: 10px;
}

.coupon-input-container {
  display: flex; /* Add CSS property */
  justify-content: center; /* Add CSS property */
}

.coupon-input {
  padding: 10px;
  font-size: 16px;
  border: 2px solid #ccc;
  border-radius: 5px;
  margin-right: 10px; /* Add CSS property */
  width: 300px;
  max-width: 100%;
}

.coupon-button {
  background-color: #3b5e75;
  color: white;
  padding: 10px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.img-overlay {
  position: absolute;
  background: rgb(0, 0, 0);
  background: rgb(89 83 83 / 50%); /* Black see-through */
  color: #f1f1f1;
  opacity: 1;
  color: white;
  font-size: 20px;
  padding: 20px;
  text-align: center;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.coupon-success {
  color: green;
  margin-top: 10px;
  margin-bottom: 0px;
}

.coupon-fail {
  color: red;
  margin-top: 10px;
  margin-bottom: 0px;
}

@media screen and (max-width: 600px) {
  .toggle-label {
    font-size: 16px;
    line-height: 40px;
  }
}
@media screen and (min-width: 601px) and (max-width: 900px) {
  .toggle-label {
    font-size: 18px;
    line-height: 45px;
  }
}
@media screen and (min-width: 901px) {
  .toggle-label {
    font-size: 20px;
    line-height: 50px;
  }
}
